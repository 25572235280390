import * as secrets from "@/env-config"
import { trackPageview } from "@/lib/googleAnalytics"
import registerServiceWorker from "@/lib/registerServiceWorker"
import "@/styles/global.scss"
import "@/styles/transitions.scss"
import bugsnag, { Bugsnag } from "@bugsnag/js"
import bugsnagReact from "@bugsnag/plugin-react"
import { PageTransition } from "next-page-transitions"
import App, { Container } from "next/app"
import { Router } from "next/router"
import React from "react"
import { Provider } from "react-redux"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import withReduxStore, { Store } from "../lib/withReduxStore"

interface Props {
  reduxStore: Store
}

const hideKeyboardOnLandscapeOrientation = () => {
  const mql = window.matchMedia(
    "(orientation: landscape) and (min-aspect-ratio: 13/9)"
  )

  const isLandscapeOrientation = (e: any) => {
    if (e.matches) {
      const activeElement = document && (document.activeElement as any)
      if (activeElement) {
        activeElement.blur()
      }
    }
  }

  mql.addListener(isLandscapeOrientation)
}

let bugsnagClient: Bugsnag.Client

const getBugsnag = () => {
  if (!bugsnagClient) {
    bugsnagClient = bugsnag({
      apiKey: secrets.BUGSNAG_API_KEY,
      appVersion: secrets.BUILD_ID,
      releaseStage: secrets.ENV
    })
    bugsnagClient.use(bugsnagReact, React)
  }

  return bugsnagClient
}

Router.events.on("routeChangeComplete", trackPageview)

class MyApp extends App<Props> {
  public componentDidMount() {
    registerServiceWorker()
    hideKeyboardOnLandscapeOrientation()
  }

  public render() {
    const { Component, pageProps, reduxStore } = this.props
    const ErrorBoundary = getBugsnag().getPlugin("react")

    return (
      <ErrorBoundary>
        <PageTransition
          timeout={400}
          classNames="page-transition"
          loadingClassNames="loading-indicator"
          loadingTimeout={{
            enter: 400,
            exit: 0
          }}
        >
          <Container key={this.props.router.asPath}>
            <Provider store={reduxStore}>
              <Component {...pageProps} />
            </Provider>
          </Container>
        </PageTransition>
      </ErrorBoundary>
    )
  }
}

export default withReduxStore(MyApp as any)
