export const GA_TRACKING_ID = "UA-77162223-2"

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const trackPageview = (url: string) => {
  ;(window as any).gtag("config", GA_TRACKING_ID, {
    page_path: url
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events

interface AnalyticsEvent {
  action: string
  category: string
  label: string
  value: string
}

export const trackEvent = (event: AnalyticsEvent) => {
  ;(window as any).gtag("event", event.action, {
    event_category: event.category,
    event_label: event.label,
    value: event.value
  })
}
